import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Navigation, Pagination, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
const PicturesViewer = ({ imgs, selectedIndex }) => {
  return (
    <Swiper
      className="carousel"
      initialSlide={selectedIndex}
      keyboard={{
        enabled: true,
      }}
      loop={true}
      speed={600}
      modules={[Keyboard, Navigation, Pagination, A11y]}
      spaceBetween={10}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
    >
      {imgs.map((image, index) => (
        <SwiperSlide key={index}>
          <img src={image.url} alt="" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
export default PicturesViewer;
