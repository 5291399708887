import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L, { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import pinIcon from "../assets/icons/location-pin.png";
import logo from "../assets/img/logo-moulin-casta.png";

const Map = () => {
  const center = [42.60369157717947, 9.28968023902561];
  const customIcon = new Icon({
    iconUrl: pinIcon,
    iconSize: [38, 38],
  });

  return (
    <MapContainer
      center={center}
      zoom={8}
      style={{ height: "400px", width: "100%" }}
      scrollWheelZoom={false} // Disable zoom on scroll
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
        attribution='&copy; OpenStreetMap France | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />

      <Marker position={center} icon={customIcon}>
        <Popup>
          <img src={logo} alt="" style={{ maxHeight: "50px" }} />
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default Map;
