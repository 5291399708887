import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const API_URL = "https://api.moulincasta.fr/api/v1/global";

export const fetchGlobalData = createAsyncThunk(
  "globalData/fetchGlobalData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(API_URL);
      return response.data.globals[0];
    } catch (error) {
      console.error("Error fetching global data:", error);
      return rejectWithValue("Failed to fetch global data");
    }
  }
);

const globalDataSlice = createSlice({
  name: "globalData",
  initialState: {
    globalData: null,
    isLoading: true,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGlobalData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchGlobalData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.globalData = action.payload;
      })
      .addCase(fetchGlobalData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default globalDataSlice.reducer;
