import React from "react";
import logo from "../assets/img/logo-moulin-casta.png";
const NotFound = () => {
  return (
    <div className="not-found">
      <div>
        <h1>404 ERROR</h1>
        <h3>Page not found</h3>
      </div>
    </div>
  );
};

export default NotFound;
