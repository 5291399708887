import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { changeLoginModalState, loginAsync } from "../feature/loginSlice";
import xMark from "../assets/icons/circle-xmark.png";

const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLoginClick = async () => {
    try {
      await dispatch(loginAsync({ email, password }));
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="login">
      <img
        src={xMark}
        className="close-modal"
        onClick={() => {
          dispatch(changeLoginModalState(false));
        }}
        alt="Close Modal"
      />
      <h4>Connection</h4>
      <div className="inputs-login">
        <div className="email-login">
          <label>Email:</label>
          <input type="text" onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="password-login">
          <label>Password:</label>
          <input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
      </div>
      <button className="btn-edit" onClick={handleLoginClick}>
        Se connecter
      </button>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default Login;
