import { configureStore } from "@reduxjs/toolkit";
import accommodationsReducer from "./feature/accommodationsSlice";
import loginReducer from "./feature/loginSlice";
import globalReducer from "./feature/global.slice";
export const store = configureStore({
  reducer: {
    accommodations: accommodationsReducer,
    login: loginReducer,
    globalData: globalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
