import React, { useState } from "react";
import { IoCloseCircle } from "react-icons/io5";
import PicturesViewer from "./PicturesViewer";

const ImgSlider = ({ images }) => {
  const [modal, setModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const openModal = (index) => {
    setSelectedImageIndex(index);
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
    setSelectedImageIndex(null);
  };

  return (
    <div className="imgSlider">
      {modal && (
        <div className="carousel-gallery-modal">
          <IoCloseCircle className="close-modal-icon" onClick={closeModal} />
          <PicturesViewer imgs={images} selectedIndex={selectedImageIndex} />
        </div>
      )}
      <div className="grid">
        {images &&
          images.slice(0, 5).map((image, index) => (
            <div
              key={image.imageName}
              className={
                index === 0
                  ? "main img-container skeleton"
                  : "img-container skeleton"
              }
            >
              <img
                loading="lazy"
                key={image.imageName}
                className="img-slider"
                src={image.url}
                alt=""
                onClick={() => openModal(index)}
              />
            </div>
          ))}
        {images.length > 5 && (
          <button onClick={() => openModal(0)} className="button-show-pictures">
            Voir toutes les photos
          </button>
        )}
      </div>
    </div>
  );
};

export default ImgSlider;
