import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  loginStatus: false,
  viewClient: false,
  loginModalState: false,
};

export const loginAsync = createAsyncThunk(
  "login/loginAsync",
  async ({ email, password }, { dispatch }) => {
    try {
      const response = await axios.post(
        "https://api.moulincasta.fr/auth/login",
        {
          email,
          password,
        }
      );
      const token = response.data.token;
      localStorage.setItem("SavedToken", "Bearer " + token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      dispatch(changeLoginStatus());
      dispatch(changeLoginModalState(false));
      dispatch(changeViewMode(false));
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error("mot de passe ou email invalide");
      } else if (error.request) {
        throw new Error("No response from server");
      }
      throw error;
    }
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    changeLoginStatus: (state) => {
      const tokenExist = localStorage.getItem("SavedToken");
      state.loginStatus = !!tokenExist;
    },
    changeViewMode: (state, action) => {
      state.viewClient = action.payload;
    },
    changeLoginModalState: (state, action) => {
      state.loginModalState = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.fulfilled, (state) => {
        // Handle additional logic after successful login if needed
      })
      .addCase(loginAsync.rejected, (state, action) => {
        // Handle additional logic after unsuccessful login if needed
      });
  },
});

export default loginSlice.reducer;
export const { changeLoginStatus, changeViewMode, changeLoginModalState } =
  loginSlice.actions;
