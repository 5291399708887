import { Helmet } from "react-helmet-async";
import Footer from "../components/Footer";
import Infos from "../components/Infos";
import Presentation from "../components/Presentation";

const Home = () => {
  document.body.style.overflow = "unset";
  return (
    <div className="home">
      <Helmet>
        <title>Le Moulin Casta - San Gavino Di Tenda</title>
        <meta
          name="description"
          content="Location villa et studio en Corse, à San Gavino Di Tenda, en pleine nature avec piscine, Le Moulin Casta pour 9 personnes et La Bergerie du Moulin Casta pour 4 personnes."
        />
        <link rel="canonical" href="https://moulincasta.fr" />
      </Helmet>
      <Presentation />
      <Infos />
      <Footer />
    </div>
  );
};

export default Home;
