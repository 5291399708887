import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { fetchAccommodations } from "./feature/accommodationsSlice";
import NavBar from "./components/NavBar";
import Accommodation from "./pages/Accommodation";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Loading from "./components/Loading";
import { changeLoginStatus } from "./feature/loginSlice";
import { fetchGlobalData } from "./feature/global.slice";
import NotFound from "./pages/NotFound";

const App = () => {
  const dispatch = useDispatch();
  const { accommodations, isLoading } = useSelector(
    (store) => store.accommodations
  );
  const isLoadingGlobal = useSelector((store) => store.globalData.isLoading);
  useEffect(() => {
    dispatch(fetchAccommodations());
    dispatch(fetchGlobalData());
    dispatch(changeLoginStatus());
  }, [dispatch]);

  if (isLoading && isLoadingGlobal) {
    return <Loading />;
  }
  return (
    <BrowserRouter>
      <NavBar data={accommodations} />
      <div className="content"></div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        {accommodations.map((accommodation) => {
          return (
            <Route
              key={accommodation._id}
              path={"/" + accommodation.name.split(" ").join("-").toLowerCase()}
              element={<Accommodation data={accommodation} />}
            />
          );
        })}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
