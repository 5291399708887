import { useRef } from "react";
import { useSelector } from "react-redux";
import { IoChevronDownCircleOutline } from "react-icons/io5";
import Slideshow from "./Slideshow";
import Share from "./Share";
import { NavLink } from "react-router-dom";
import Loading from "./Loading";

const Presentation = () => {
  const targetRef = useRef(null);
  const scrollToTarget = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const { accommodations } = useSelector((state) => state.accommodations);
  const { globalData, isLoading } = useSelector((state) => state.globalData);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className="main-container">
      <div className="main skeleton">
        <video
          loading="lazy"
          src={globalData.video.url}
          poster={globalData.images.mainImg.url}
          fetchpriority="high"
          preload="auto"
          loop
          autoPlay
          muted
          playsInline
        />
        <div className="header-main-container">
          <h1>Venez vivre une expérience unique en Haute-Corse</h1>
          <p>En pleine nature et à seulement 20 minutes de Saint-Florent</p>
          <IoChevronDownCircleOutline
            className="chevron-down icon"
            onClick={() => scrollToTarget(targetRef)}
          />
        </div>
      </div>
      <div className="overview" id="presentation">
        <div className="intro">
          <p className="overview-text">
            Découvrez un havre de paix en Corse, niché au cœur d'une nature
            préservée. Deux logements disponibles, loués à des périodes
            différentes pour assurer une tranquillité totale à nos locataires.
            Un logement peut acceuilir jusqu'à 9 personnes et l'autre jusqu'à 4
            personnes. Nous vous garantissons calme et détente avec une rivière
            à quelques pas du Moulin dans laquelle vous pourrez vous
            rafraîchir...
          </p>
          <img
            src={globalData.images.miniature.url}
            className="circle-img skeleton"
            ref={targetRef}
          />
        </div>
        <div className="accommodations-cards">
          {accommodations.map((accommodation) => (
            <div key={accommodation._id} className="accommodation-card">
              <div className="title-share">
                <NavLink to={`/${accommodation._id}`}>
                  <h2 className="accomodation-title">{accommodation.name}</h2>
                </NavLink>
                <Share
                  title={accommodation.name}
                  message={`${accommodation.description.substring(0, 80)}...`}
                  url={encodeURI(window.location.href) + accommodation._id}
                />
              </div>
              <Slideshow
                name={accommodation.name}
                images={accommodation.pictures.slice(0, 5)}
              />
              <div className="text-card">
                <h5 className="extra-title">
                  {accommodation.extraInfo
                    ? accommodation.extraInfo
                    : `À partir de ${accommodation.defaultRate}€ par nuit`}
                </h5>
                <div className="accomodation-text-container">
                  <p>{accommodation.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Presentation;
