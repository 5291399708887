import { AiFillGithub } from "react-icons/ai";
import { BsPhone } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";

import logo from "../assets/img/logo-moulin-casta.png";
import { useSelector } from "react-redux";
import Loading from "./Loading";

const Footer = () => {
  const { globalData, isLoading } = useSelector((state) => state.globalData);
  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className="footer">
      <div className="attributions">
        <a
          className="by-victor"
          target="_blank"
          href="https://github.com/Nikitasgit"
        >
          <p>Site by Victor Leman</p>
          <AiFillGithub className="icon" />
        </a>
        <p>Video de La Bergerie par Hugo Barret-Castan</p>
        <p>Icons made by Freepik from www.flaticon.com</p>
      </div>

      <div className="text-and-logo">
        <p className="text-footer">
          Aucun réglement ne vous sera demandé sur ce site, vous pouvez faire
          une demande de réservation sur la page dédiée au logement qui vous
          intéresse. Nous vous contacterons au plus vite pour vous renseigner
          sur les modalités.
        </p>
        <img className="logo" src={logo} alt="" />
      </div>
      <div className="contact-footer">
        <div className="person-contact">
          <img
            loading="lazy"
            src={globalData.images.profil.url}
            alt="Person to contact"
            className="img-contact
               skeleton"
          />
        </div>
        <div className="contact-infos">
          <h5>{globalData.contact.firstName}</h5>
          <div className="contact-phone">
            <BsPhone className="icon" />
            <a href="tel:+33650224942">{globalData.contact.phone}</a>
          </div>
          <div className="contact-email">
            <HiOutlineMail className="icon" />
            <a href="mailto:moulincasta@gmail.com">
              {globalData.contact.email}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
