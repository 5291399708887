import logo from "../assets/img/logo-moulin-casta.png";
import Share from "./Share";
import Login from "./Login";
import { GoPerson } from "react-icons/go";

import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
//FUNCTIONS
import {
  changeLoginModalState,
  changeLoginStatus,
} from "../feature/loginSlice";
import { outsideClick } from "../functions/OutsideClickFunction";
//ICONS
import { NavLink, useLocation } from "react-router-dom";
import { FaListUl } from "react-icons/fa";
import { CiLogin } from "react-icons/ci";
import { CiLogout } from "react-icons/ci";
import { SlEnvolopeLetter } from "react-icons/sl";

const NavBar = ({ data }) => {
  const [lastScrollY, setLastScrollY] = useState(0);
  const [dropdown, setDropdown] = useState(false);
  const login = useSelector((state) => state.login.loginStatus);
  const dropdownRef = useRef();
  const dropdownButton = useRef();
  const location = useLocation();
  const dispatch = useDispatch();
  const setAlertLogOut = () => {
    if (confirm("Vous allez vous déconnecter, souhaitez-vous continuer?")) {
      localStorage.removeItem("SavedToken");
      dispatch(changeLoginStatus());
    }
  };

  const modalState = useSelector((state) => state.login.loginModalState);
  outsideClick(dropdownRef, setDropdown, dropdownButton);
  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        setDropdown(false);
      } else {
        setDropdown(false);
      }

      setLastScrollY(window.scrollY);
    }
  };
  useEffect(() => {
    setDropdown(false);
  }, [location]);
  useEffect(() => {
    if (modalState) {
      const scrollBarWidth = getScrollbarWidth();
      document.body.style.marginRight = `${scrollBarWidth}px`;
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
      document.body.style.marginRight = ``;
    }
  }, [modalState]);
  function getScrollbarWidth() {
    // Creating invisible container
    const outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.overflow = "scroll"; // forcing scrollbar to appear
    outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement("div");
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <div>
      <nav className={"nav-active"}>
        <NavLink to="/">
          <img className="logo" src={logo} alt="logo-moulin-casta" />
        </NavLink>
        <input
          className="hamburger-menu"
          type="checkbox"
          style={{ display: "none" }}
        />
        <div className="menu">
          <div
            className="dropdown-button"
            ref={dropdownButton}
            onClick={() => {
              dropdown ? setDropdown(false) : setDropdown(true);
            }}
          >
            <FaListUl className="icon" />
            <h4>Nos logements</h4>
          </div>
          <NavLink className="contact-navbar-container" to="/contact">
            <SlEnvolopeLetter className="icon" />
            <h4>Nous contacter</h4>
          </NavLink>
          <div className="login-navbar">
            {login ? (
              <CiLogout className="icon" onClick={() => setAlertLogOut()} />
            ) : (
              <CiLogin
                className="icon"
                onClick={() => {
                  dispatch(changeLoginModalState(true));
                }}
              />
            )}
          </div>
          <Share
            title={document.querySelector("title").textContent}
            url={encodeURI(window.location.href)}
            message={"Venez découvrir Le Moulin Casta!"}
          />
        </div>
      </nav>
      {dropdown && (
        <div className="dropdown" ref={dropdownRef}>
          <div className="dropdown-first-container">
            {data.map((accommodation) => (
              <NavLink
                to={`/${accommodation.name.split(" ").join("-").toLowerCase()}`}
                className="dropdown-element"
                key={accommodation._id}
              >
                <img
                  className=" img-dropdown skeleton"
                  src={accommodation.pictures[0].url}
                  alt={accommodation.name}
                />
                <div className="dropdown-element-text">
                  <h3>{accommodation.name}</h3>
                  {/*    <p>{`à partir de ${accommodation.defaultRate}€ par nuit`}</p> */}
                  <p> {accommodation.capacity} personnes</p>
                </div>
              </NavLink>
            ))}
          </div>
          <div className="dropdown-second-container">
            <p>
              Les logements ne sont jamais loués en même temps pour vous
              garantir une tranquilité absolue lors de votre séjour.
            </p>
          </div>
        </div>
      )}
      {modalState && <Login />}
    </div>
  );
};

export default NavBar;
